.ticket__filters {
  background-color: #4285f4;
}

.ticket__content__item {
  background-color: #fff;
  /* -openkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), */
    /* 0 2px 5px 0 rgba(0, 0, 0, 0.26); */
  /* box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26), 0 2px 5px 0 rgba(0, 0, 0, 0.26); */
  -ms-flex-preferred-size: calc((100% - (16px * 3)) / 4);
  flex-basis: calc((100% - (16px * 4)) / 4);
  margin: 0 8px 16px;
  padding: 0;
  border-radius: 5px !important;
  background-color: #ffffff !important;
  /* margin-bottom: 30px !important; */
  /* padding: 50px 30px !important; */
  -webkit-box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%) !important;
  -moz-box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.1) !important;
  box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%) !important;
  
}

/* remove default border */
.ticket__content__item:not(:last-child) {
  border-bottom: none;
}

.ticket__wrapper {
  padding: 32px 12px 0;
}

/* * Custom styles for bottom directory items */

.card-box__item__profile,
.card-box__item__bottom {
  padding: 20px;
  text-align: center;
}

.card-box__item__profile-pic-holder {
  background-size: cover;
  border-radius: 50%;
  display: block;
  height: 100px;
  margin: 0 auto 20px;
  overflow: hidden;
  position: relative;
  width: 100px;
}

.card-box__item__name {
  margin: 0 0 4px;
}

.card-box__item__location,
.card-box__item__experience,
.card-box__item__bottom {
  font: 400 14px/20px Roboto, sans-serif;
}

.card-box__item__bottom--closed {
    color: #fff;
    /* color: #000; */
  /* background: #77c159; */
  background: #1DC789;
}

.card-box__item__bottom--open {
    /* color: #fff; */
    color: #000;
    /* background: #f1f3f4; */
    /* background: #FF0505; */
    /* background: #FFC107; */
    background: #FAC63E;
}

.card-box__item__bottom--waiting {
    color: #fff;
    /* background: #5ec9e7; */
    background: #37B8CD;
}

.card-box__item__bottom--play {
    color: #fff;
  background: #f1f3f4;
}

.card-box__item__badge {
  margin-bottom: 12px;
  width: 60px;
}

/*  Overrides for items in the map info window */

.card-box__item--map {
  min-width: 240px;
}

.card-box__item--map .card-box__item__profile,
.card-box__item--map .card-box__item__bottom {
  padding: 10px;
}

.card-box__item--map .card-box__item__profile-pic-holder {
  height: 80px;
  margin-bottom: 10px;
  width: 80px;
}

.ticket__content__item {
  flex-basis: calc((100% - (16px * 3)) / 3);
}

/*
     * Media queries are based on the iframe's max width, not the top document
     */

@media screen and (max-width: 1000px) {
  .ticket__content__item {
    flex-basis: calc((100% - (16px * 3)) / 3);
  }
}

@media screen and (max-width: 720px) {
  .ticket__content__item {
    flex-basis: calc((100% - 16px * 2) / 2);
  }
}

@media screen and (max-width: 500px) {
  .ticket__content__item {
    flex-basis: calc(100% - 16px);
  }
}
