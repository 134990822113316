

@media screen and (max-width: 400px) {
    
    .for-mobile-screen{
        height: auto !important;
        margin-top: 0.3rem;
    }
  }

  @media screen and (max-width: 766px) {

    .for-mobile-screen{
        height: auto !important;
        margin-top: 0.3rem;
    }
  }

