
.all-tickets-container{
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}
  
  
  .border-black {
    /* border: 1px solid #000 !important; */
  }

  .all-tickets-card{
      box-shadow: 0px 0px 10px rgb(204, 201, 201);
      padding: 1rem 2rem;
  }

  .bg-total{
      background: #4F71DD;
      color: #fff !important;
  }

  .bg-closed{
      background: #1DC789;
      color: #fff !important;
  }

  .bg-waiting{
      background: #37B8CD;
      color: #fff !important;
  }

  .bg-open{
      background: #FAC63E;
      color: #fff !important;
  }