/* ------------------------------------------------------ */
/* ############### 1. GOOGLE FONTS IMPORT ############### */
/* ------------------------------------------------------ */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");
@import url("https://fonts.googleapis.com/css?family=Fira+Sans:100,200,300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@import url("https://fonts.googleapis.com/css?family=Libre+Barcode+39+Extended+Text");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ht-100v {
  height: 100vh;
}

















/* Shapater CSS */
.animation {
  text-align: center;
  color: #dde3e9;
  padding: 1rem;
  background: linear-gradient(90deg, 	#9BB4C5, #8BA6B9, 	#8BA6B9, #c4c9ce);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  border-radius: 4rem;
  border: 2px solid #9BB4C5;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
    color: black;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    color: white;
  }
  100% {
    background-position: 0% 50%;
    color: black;
  }
}


.backgroundImage{
background-color: #EEEEEE;
  
}
.backgroundImage2{
  background-image: url('./assets/wave3.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  
}

/* .row{
  width: 100% !important;
} */