.cursor-pointer {
  cursor: pointer !important;
}

.feature_item {
  border-radius: 5px !important;
  padding: 50px 30px !important;
  box-shadow: 2px 6px 15px 0px rgb(69 65 78 / 10%) !important;
  height: 200px;
  color: black;
  font-size: 18px;
  font-weight: bold;
  border-radius: 11px !important;
}

.feature_item:hover {
  box-shadow: 2px 6px 15px 0px rgba(69, 65, 78, 0.292) !important;
  padding: 50px 30px !important;
  /* border-radius: 5px !important; */
  border-radius: 11px !important;
  background-color: #9a2232;
  color: #fff !important;
}
